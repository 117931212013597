//Dependencies
import React, { useState } from "react"
import cx from "classnames"
import CmsText from "../../cms/cms-text/cms-text"

//Style
import "./product-review-block.scss"
import "swiper/css"
import "swiper/css/mousewheel"
import "swiper/css/navigation"
import "swiper/css/scrollbar"
import ReviewStarAmount from "../../general/review-star-amount/reviewStarAmount"
import moment from "moment/moment"
import useLipscoreReviews from "../../../hooks/useLipscoreReviews"
import LipscoreWidget from "../../general/lipscore-widget/lipscore-widget"

const ProductReviewBlock = props => {
  const componentProps = {} //props.blok ? props.blok : props.prop
  const {
    lipscoreReviews,
    lipscoreStars,
    lipscoreRatingNumber,
    lipscoreReviewsCountString,
  } = useLipscoreReviews({
    internal_id: props.productInternalId,
  })
  const { title, reviewCountText, loadMoreText } = props
  const [limit, setLimit] = useState(2)

  if (
    !lipscoreReviews ||
    !Array.isArray(lipscoreReviews) ||
    lipscoreReviews.length === 0
  ) {
    return undefined
  }

  return (
    <div
      className={cx(
        "product-review-block",
        {
          "padding-top": componentProps.paddingTop,
        },
        {
          "padding-bottom": componentProps.paddingBottom,
        }
      )}
    >
      <div className="grid-container">
        <LipscoreWidget
          className="product-review-block__hidden"
          lipscoreId="lipscore-rating"
          productId={props.productInternalId}
        />
        <div className="grid__row">
          <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
            <div className="product-review-block__heading">
              <div className="product-review-block__heading-heading">
                {componentProps.heading &&
                  componentProps.heading.map((text, index) => (
                    <CmsText
                      key={index}
                      color={text.color ? text.color : "black"}
                      text={text.text}
                      size={text.size}
                      tag={text.tag ? text.tag : "p"}
                      fontWeight={text.fontWeight}
                    />
                  ))}
                <CmsText text={title} size="large" tag="p" />
              </div>
            </div>
            <div className="product-review-block__ratings">
              <div className="product-review-block__ratings__left">
                <span className="product-review-block__ratings__left__rating">
                  {lipscoreRatingNumber}
                </span>
                <ReviewStarAmount starAmount={lipscoreStars} />
                <span className="product-review-block__ratings__left__count">
                  {(reviewCountText || "{{REVIEW_COUNT}}").replace(
                    "{{REVIEW_COUNT}}",
                    lipscoreReviewsCountString
                  )}
                </span>
              </div>
              {/* <a href="#">Write a review here</a> */}
            </div>
            {lipscoreReviews &&
              Array.isArray(lipscoreReviews) &&
              lipscoreReviews.slice(0, limit).map((item, index) => (
                <div key={index} className="product-review-block__item">
                  <div className="product-review-block__item__heading">
                    <div className="product-review-block__item__upper-left">
                      <CmsText
                        text={moment(item.created_at).format("MMM D YYYY")}
                        size="xxx-small"
                        tag="p"
                      />
                    </div>
                    <div className="product-review-block__item__upper-middle">
                      <ReviewStarAmount starAmount={item.rating} />
                    </div>
                  </div>
                  <div className="product-review-block__item__text">
                    <CmsText
                      text={item.user.name}
                      size="small-fixed"
                      fontWeight="semibold"
                      tag="p"
                      className="product-review-block__item__text__name"
                    />
                    <CmsText text={item.text} size="xxsmall" tag="p" />
                  </div>
                </div>
              ))}
          </div>
          {limit < lipscoreReviews.length ? (
            <button
              className="product-review-block__show-more"
              onClick={() => setLimit(limit + 2)}
            >
              {loadMoreText}
            </button>
          ) : undefined}
        </div>
      </div>
    </div>
  )
}

export default ProductReviewBlock
